.order-status-icon {
    text-align: center;
    align-content: center;
    position: relative;
    width: 18px;
    height: 18px;
    margin: 0 auto;
}

.order-status-complete {
    margin: 2px auto;
    background-position: center center;
    background-color: #008296;
    border-radius: 50%;
}

.order-progress-bar::after {
    height: 45px;
    content: '';
    align-content: center;
    position: relative;
    display: block;
    left: 45%;
    width: 3px;
}

@for $i from 0 through 100 {
    .progress-#{$i}::after {
        background: linear-gradient(to bottom, #008296 0, #008296 percentage($i/100), lightgrey percentage($i/100), lightgrey percentage((100 - $i)/100));
    }
}
 